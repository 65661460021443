<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm passwordChangeArea">
        <template slot="card-title-custom">
          <div class="titleArea">
            <div class="title">
              대동 – ESF 파트너 <font class="emphasis">접속 아이디를</font> 변경 해 주세요.”<br/>
              사업자번호 아이디는 사용할 수 없습니다.
            </div>
          </div>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <c-label-text 
              title="기존 아이디" 
              style="padding-bottom: 25px !important;"
              :value="portalInfo.loginId" />
          </div>
          <div class="col-12">
            <c-text
              :required="true"
              label="변경할 아이디 - (영문+숫자 조합 5자리 이상)"
              name="portalId"
              type="text"
              v-model="portalInfo.portalId">
            </c-text>
          </div>
          <div class="col-12" style="text-align:right">
            <q-btn-group outline >
              <c-btn label="아이디 변경" icon="save" @btnClicked="saveId" />
            </q-btn-group>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import transactionPartnerConfig from '@/js/transactionPartnerConfig';
export default {
  name: 'initChangePw',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          vendorCd: '',
          loginId: '',
        }
      },
    },
  },
  data() {
    return {
      portalInfo: {
        vendorCd: '',
        loginId: '',
        portalId: '',
        chgUserId: '',
      },
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.saveUrl = transactionPartnerConfig.mdm.cim.vendor.chgId.url;
      this.portalInfo.vendorCd = this.popupParam.vendorCd;
      this.portalInfo.loginId = this.popupParam.loginId;
      this.portalInfo.chgUserId = this.$store.getters.user.userId;
    },
    saveId() {
      let idReg = {
        lowerCase: /[a-z]/g,
        upperCase: /[A-Z]/g,
        numberCase: /[0-9]/g,
        lowerCaseTest: false,
        upperCaseTest: false,
        numberCaseTest: false,
      };   
      if (this.portalInfo.portalId === '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '변경할 아이디를 입력하세요', //변경할 아이디를 입력하세요,
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        this.$set(idReg, 'lowerCaseTest', idReg.lowerCase.test(this.portalInfo.portalId));
        this.$set(idReg, 'upperCaseTest', idReg.upperCase.test(this.portalInfo.portalId));
        this.$set(idReg, 'numberCaseTest', idReg.numberCase.test(this.portalInfo.portalId));
        let resultTest = this.$_.filter(idReg, (value) => {
          return value === false
        })
        if (resultTest.length > 1 || this.portalInfo.portalId.length < 5) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '아이디는 최소 5자리여야 하며,\r\n 영문/숫자를 포함해야합니다.',
            type: 'warning', // success / info / warning / error
          });
        } else {
          this.$http.url = this.saveUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.portalInfo;
          this.$http.request((_result) => {
            if (_result.data == 'DUPLICATE') {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '사용중인 아이디가 있습니다.', // 사용중인 아이디가 있습니다.
                type: 'warning', // success / info / warning / error
              });
            } else {
              this.$emit('closePopup', 'change2');
            }
          },);
        }
      }
    },
  }
};
</script>
<style lang="sass">
.passwordChangeArea
  .titleArea
    width: 100%
    .title
      font-size:2em
      font-weight: 700
      text-align: center
      .emphasis
        color: #C10015
    .subtitle
      font-size:1.05em
      font-weight: 500
</style>